import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// s4n
import { useIntl } from "gatsby-plugin-intl"
import Link from './../components/s4n/Link' // extends Link from gatsby-plugin-intl


const NotFoundPage = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    return (
        <Layout className={`error error-404`}>
            <SEO title="404: Not found" />

            <section class="page-404 section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            {/* <a href="index.html">
                                <img src="images/logo.png" alt="site logo" />
                                <h2>ELITE THEME</h2>
                            </a> */}
                            {/* <h1>404</h1>
                            <h2>Page Not Found</h2> */}
                            <h1>404</h1>
                            <h2>{ t({ id: `soft4net_error_http_404_page_not_found_title` }) }</h2>
                            <h3>{ t({ id: `soft4net_error_http_404_page_not_found_content` }) }</h3>
                            <Link to={`/`} title={`Home`} className="btn btn-primary mt-4">
                                <i class="ti-angle-double-left"></i> { t({ id: `soft4net_page_home` }) }
                            </Link>
                            {/* <p class="copyright-text">© 2018 Themefisher All Rights Reserved</p> */}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage